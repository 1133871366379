import React from "react";
import { BeatLoader } from "react-spinners";

export default function Loader({
  loading,
  className,
  color = "#059F8B",
  size,
}) {
  return (
    <div
      className={`min-h-[80px] flex justify-center items-center ${className}`}
    >
      <BeatLoader loading={loading} color={color} size={size} />
    </div>
  );
}
