import { getAverage, getGrade } from "../../../../../shared/utils/functions";

import EmptyTable from "../../../../../shared/components/EmptyTable";
import Loader from "../../../../../shared/components/Loader";

const ReportCardTable = ({ loading, data, handleSelect }) => {
  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-9 border-b">
          <div className="col-span-3 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">Obtainable Score</h5>
          </div>

          <div className="p-3 border-r col-span-2">
            <h5 className="font-semibold text-sm">Obtained Score</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Average</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Grade</h5>
          </div>

          {/* <div className="p-3">
            <h5 className="font-semibold text-sm">Action</h5>
          </div> */}
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            // image={subjectBookImage}
            message={"No records found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((card, i) => (
          <div
            className="grid grid-cols-9 border-b table_row"
            key={i}
            onClick={() => handleSelect(card)}
          >
            <div className="col-span-3 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {card?.lastName} {card?.firstName}
              </p>
            </div>

            <div className="border-r p-3 col-span-2">
              <p className="text-independence text-sm">
                {card?.obtainableScore}
              </p>
            </div>

            <div className="p-3 border-r col-span-2">
              <p className="text-independence text-sm">{card?.obtainedScore}</p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {`${getAverage(card?.obtainedScore, card?.obtainableScore)}%`}
              </p>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {getGrade((card?.obtainedScore / card?.obtainableScore) * 100)}
              </p>
            </div>

            {/* <div className="p-3">
              <FiEdit3 />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportCardTable;
