import { NavLink, useParams } from "react-router-dom";

import Loader from "../../../shared/components/Loader";
import IMAGES from "../../../shared/constant/images";
import useFetchActiveClassRoles from "../../../shared/api/dashboard/useFetchActiveClassRoles";

const ClassRoleTable = () => {
  const { teacherId } = useParams();
  const [roles, loading] = useFetchActiveClassRoles();

  function getTotalStudents(params) {
    let maleStudents = params?.maleStudents || 0;
    let femaleStudents = params?.femaleStudents || 0;

    return maleStudents + femaleStudents;
  }

  return (
    <div className="bg-white shadow-sm py-5">
      <div className="pb-5 px-3 border-b">
        <h2 className="text-yankee_blue font-semibold text-base">
          Class Roles
        </h2>
      </div>

      <div className="table_wrapper">
        <div className="min-w-[700px]">
          {/* table header */}
          <div className="grid grid-cols-6 border-b">
            <div className="col-span-2 border-r p-3">
              <h5 className="font-semibold text-sm">School Name</h5>
            </div>

            <div className="border-r p-3">
              <h5 className="font-semibold text-sm">Class</h5>
            </div>

            <div className="p-3 border-r">
              <h5 className="font-semibold text-sm">Total Students</h5>
            </div>

            <div className="p-3 border-r">
              <h5 className="font-semibold text-sm">Category</h5>
            </div>

            <div className="p-3">
              <h5 className="font-semibold text-sm">Action</h5>
            </div>
          </div>

          {loading ? <Loader loading={loading} /> : null}

          {!loading && !roles ? (
            <div className="grid place-items-center py-5">
              <div className="h-32 w-32 mb-4">
                <img
                  src={IMAGES.subjectBook}
                  alt=""
                  className="h-full w-full object-contain"
                />
              </div>

              <p className="text-xl font-semibold">Oops!</p>
              <p className="text-sm text-auro_metal_saurus">
                You haven't been assigned to a class role yet
              </p>
            </div>
          ) : null}

          {/* Table body */}
          {roles?.map((classRole, i) => (
            <div className="grid grid-cols-6 border-b" key={i}>
              <div className="col-span-2 border-r p-3">
                <p className="text-independence text-sm capitalize">
                  {classRole?.school?.name}
                </p>
              </div>

              <NavLink
                to={`/${teacherId}/workspace/${classRole?.school?.schoolId}/class/${classRole?.classId}/students`}
              >
                <div className="border-r p-3">
                  <p className="text-primary text-sm cursor-pointer underline">
                    {`${classRole?.category} ${classRole?.suffix}`}
                  </p>
                </div>
              </NavLink>

              <div className="p-3 border-r">
                <p className="text-independence text-sm">
                  {getTotalStudents(classRole)}
                </p>
              </div>

              <div className="p-3 border-r">
                <p className="text-independence text-sm">
                  {classRole?.category?.startsWith("J")
                    ? "Junior Class"
                    : "Senior Class"}
                </p>
              </div>

              <div className="p-3">
                <p className="text-independence text-sm">-</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClassRoleTable;
