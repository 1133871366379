import { useNavigate, useParams } from "react-router-dom";

import EmptyTable from "../../../../../shared/components/EmptyTable";
import Loader from "../../../../../shared/components/Loader";

const StudentTable = ({ studentsLoading, students, studentId }) => {
  const { classId, schoolId, teacherId } = useParams();
  const navigate = useNavigate();

  function handleNavigate(params) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/students?selected=${params?.studentId}`
    );
  }

  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-6 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">D.O.B</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Parent Number</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Gender</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Date Joined</h5>
          </div>
        </div>

        {studentsLoading ? <Loader loading={studentsLoading} /> : null}

        {!studentsLoading && !students ? (
          <EmptyTable
            //   image={subjectBookImage}
            message={"No student record found"}
          />
        ) : null}

        {/* Table body */}
        {students?.map((student, i) => (
          <div
            className={`grid grid-cols-6 border-b table_row ${
              studentId === student?.studentId ? "active_table_row" : ""
            }`}
            key={i}
            onClick={() => handleNavigate(student)}
          >
            <div className="col-span-2 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {student?.lastName} {student?.firstName}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm">{student?.dob}</p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {student?.parentContact}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {student?.gender?.[0]}
              </p>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {new Date(student?.createdAt)?.toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default StudentTable;
