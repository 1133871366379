import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./pages/auth/Login";
import Registration from "./pages/auth/Registration";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetEmailSent from "./pages/auth/ResetEmailSent";
import ResetPassword from "./pages/auth/ResetPassword";
import Dashboard from "./pages/Dashboard";
import VerifyPasswordReset from "./pages/auth/VerifyPasswordReset";
import AcceptInvite from "./pages/auth/AcceptInvite";
import InviteRegistration from "./pages/auth/InviteRegistration";
import VerifyEmail from "./pages/auth/VerifyEmail";
import Container from "./shared/components/Container";
import Profile from "./pages/Profile";
import Jobs from "./pages/Jobs";
import Notifications from "./pages/Notifications";
import Notification from "./pages/Notifications/Notification";
import Workspace from "./pages/Workspace";
import Students from "./pages/Workspace/class/Students";
import Subjects from "./pages/Workspace/class/Subjects";
import Attendance from "./pages/Workspace/class/Attendance";
import Scorecards from "./pages/Workspace/class/Scorecards";
import ReportCards from "./pages/Workspace/class/ReportCards";
import ClassSubject from "./pages/Workspace/subject/ClassSubject";
import StudentReportCard from "./pages/Workspace/class/StudentReportCard";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Registration />} />
        <Route path="register" element={<Registration />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password/sent" element={<ResetEmailSent />} />
        <Route path="password/reset/:token" element={<VerifyPasswordReset />} />
        <Route path="password/change/:token" element={<ResetPassword />} />
        <Route path="invite/:inviteId" element={<InviteRegistration />} />
        <Route path="invite/accept/:inviteId" element={<AcceptInvite />} />
        <Route path="verify/email/:userId/:token" element={<VerifyEmail />} />

        <Route element={<Container />}>
          <Route path=":teacherId/dashboard" element={<Dashboard />} />

          <Route path=":teacherId/profile" element={<Profile />} />

          <Route path=":teacherId/jobs" element={<Jobs />} />

          <Route path=":teacherId/notifications" element={<Notifications />} />
          <Route
            path=":teacherId/notifications/:notificationId"
            element={<Notification />}
          />

          <Route path=":teacherId/workspace" element={<Workspace />} />

          {/* Class workspace */}
          <Route
            path=":teacherId/workspace/:schoolId/class/:classId/students"
            element={<Students />}
          />

          <Route
            path=":teacherId/workspace/:schoolId/class/:classId/subjects"
            element={<Subjects />}
          />

          <Route
            path=":teacherId/workspace/:schoolId/class/:classId/attendance"
            element={<Attendance />}
          />

          <Route
            path=":teacherId/workspace/:schoolId/class/:classId/scorecards"
            element={<Scorecards />}
          />

          <Route
            path=":teacherId/workspace/:schoolId/class/:classId/reportcard"
            element={<ReportCards />}
          />
          <Route
            path=":teacherId/workspace/:schoolId/class/:classId/student/:studentId/reportcard"
            element={<StudentReportCard />}
          />

          {/* Subject workspace */}
          <Route
            path=":teacherId/workspace/:schoolId/subject/:classSubjectId/class/:classId"
            element={<ClassSubject />}
          />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
