import ClassRoleTable from "./components/ClassRoleTable";
import SubjectRoleTable from "./components/SubjectRoleTable";

const Workspace = () => {
  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-10">Workspace</h1>

        {/* class roles */}
        <ClassRoleTable />

        {/* Subject roles */}
        <SubjectRoleTable />
      </div>
    </div>
  );
};
export default Workspace;
