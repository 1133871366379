import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

const useFetchClassDetails = (classId) => {
  const [loading, setLoading] = useState(false);

  const [classDetails, setClassDetails] = useState(null);

  const fetchClassDetails = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get(`/classes/${classId}`);

      setClassDetails(data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [classId]);

  useEffect(() => {
    if (classId) fetchClassDetails();
  }, [fetchClassDetails, classId]);

  return [loading, classDetails];
};
export default useFetchClassDetails;
