import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Pagination from "../../../../shared/components/Pagination";
import StudentsOverview from "./components/StudentsOverview";
import StudentTable from "./components/StudentTable";
import StudentDetails from "./components/StudentDetails";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import ClassNavbar from "../components/ClassNavbar";
import useFetchClassStudents from "../../../../shared/api/class/students/useFetchClassStudents";

const Students = () => {
  const { classId, teacherId, schoolId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const studentId = query.get("selected");

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);

  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [studentsLoading, students] = useFetchClassStudents(
    classId,
    page,
    setPagination
  );

  function closeSideModal() {
    navigate(`/${teacherId}/workspace/${schoolId}/class/${classId}/students`);
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">
          Students{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <ClassNavbar activeIndex={1} />

        <div className="main_content">
          <StudentsOverview
            classDetails={classDetails}
            classLoading={classLoading}
          />

          <StudentTable
            students={students}
            studentId={studentId}
            studentsLoading={studentsLoading}
          />

          <Pagination setPage={setPage} pagination={pagination} />
        </div>
      </div>

      {studentId ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar !p-0 ${studentId ? "block" : ""}`}>
        <StudentDetails studentId={studentId} closeSideModal={closeSideModal} />
      </div>
    </div>
  );
};
export default Students;
