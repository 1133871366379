import { NavLink, useParams } from "react-router-dom";

const NotificationRow = ({ notification }) => {
  const { teacherId } = useParams();
  const date = new Date(notification?.createdAt);

  return (
    <NavLink to={`/${teacherId}/notifications/${notification?.notificationId}`}>
      <div className="py-2 px-5 flex items-center gap-2 cursor-pointer mb-2">
        <p className="text-xs w-10">{`${date?.getDay()}/${
          date?.getMonth() + 1
        }`}</p>

        <div
          className={`border-2 h-14 rounded-lg ${
            notification?.isRead ? "border-greyX11" : "border-primary"
          }`}
        />

        <div className="flex-1 ml-2">
          <p className="text-xs text-manatee mb-1">{notification?.subject}</p>
          <p className="text-sm text-police_blue line-clamp-1">
            {notification?.message}
          </p>
        </div>
      </div>
    </NavLink>
  );
};

export default NotificationRow;
