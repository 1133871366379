import { useSelector } from "react-redux";
import { selectUserDetails } from "../../../redux/slices/auth/selectors";

const ProfileOverview = () => {
  const details = useSelector(selectUserDetails);

  function getBasicProfilePercent() {
    if (details) {
      let progress = 0;

      if (details?.firstName && details?.lastName) progress = progress + 10;
      // if (details?.lastName) progress = progress + 10;
      // if (details?.email) progress = progress + 10;
      // if (details?.phone) progress = progress + 10;
      if (details?.address) progress = progress + 10;
      if (details?.dob) progress = progress + 10;
      if (details?.ethnicity) progress = progress + 10;
      if (details?.gender) progress = progress + 10;
      if (details?.lga) progress = progress + 10;
      if (details?.maritalStatus) progress = progress + 10;
      if (details?.religion) progress = progress + 10;
      if (details?.nationality) progress = progress + 10;
      if (details?.stateOfOrigin) progress = progress + 10;

      return progress;
    }
  }

  return (
    <div className="bg-white px-6 py-4 rounded-md">
      <h5 className="text-independence text-sm mb-5">Profile</h5>

      <div className="flex items-start gap-2">
        <div className="flex flex-col justify-between w-full">
          {/* <p className="text-yankee_blue text-3xl font-bold">{value}</p> */}

          <div className="mt-auto grid grid-cols-2">
            <p className="text-xs text-auro_metal_saurus mb-2">
              <span className="font-bold text-primary">
                {getBasicProfilePercent()}%
              </span>{" "}
              Basic
            </p>

            <p className="text-xs text-auro_metal_saurus mb-2">
              <span className="font-bold text-secondary">0%</span> Contact
            </p>

            <p className="text-xs text-auro_metal_saurus mb-2">
              <span className="font-bold text-saffron">0%</span> Medical
            </p>

            <p className="text-xs text-auro_metal_saurus mb-2">
              <span className="font-bold text-des text-coral_red">0%</span>{" "}
              Education
            </p>

            <p className="text-xs text-auro_metal_saurus mb-2">
              <span className="font-bold text-yankee_blue">0%</span> Jobs
            </p>
          </div>
        </div>

        {/* <div className="flex-1 flex items-center justify-end">
          Basic
          <div className="h-[164px] w-[164px] rounded-full bg-anti_flash_white flex items-center justify-center relative overflow-hidden">
            <div
              className={`bg-primary absolute top-0 bottom-0 left-0 ${
                getBasicProfilePercent()
                  ? `w-[${getBasicProfilePercent()}%]`
                  : "w-0"
              }`}
            />

            <div className="h-[144px] w-[144px] rounded-full bg-lotion flex items-center justify-center z-10">
              Contact
              <div className="h-[134px] w-[134px] rounded-full bg-anti_flash_white flex items-center justify-center relative overflow-hidden">
                <div className="w-[80%] bg-secondary absolute top-0 bottom-0 left-0 right-[80%]" />

                <div className="h-[114px] w-[114px] rounded-full bg-lotion flex items-center justify-center z-10">
                  Medical
                  <div className="h-[104px] w-[104px] rounded-full bg-anti_flash_white flex items-center justify-center relative overflow-hidden">
                    <div className="w-[80%] bg-saffron absolute top-0 bottom-0 left-0 right-[80%]" />

                    <div className="h-[84px] w-[84px] rounded-full bg-lotion flex items-center justify-center z-10">
                      Education
                      <div className="h-[74px] w-[74px] rounded-full bg-anti_flash_white flex items-center justify-center relative overflow-hidden">
                        <div className="w-[80%] bg-coral_red absolute top-0 bottom-0 left-0 right-[80%]" />

                        <div className="h-[54px] w-[54px] rounded-full bg-lotion flex items-center justify-center z-10">
                          Job
                          <div className="h-[44px] w-[44px] rounded-full bg-anti_flash_white flex items-center justify-center relative overflow-hidden">
                            <div className="w-[80%] bg-yankee_blue absolute top-0 bottom-0 left-0 right-[80%]" />

                            <div className="h-[24px] w-[24px] rounded-full bg-white z-10" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default ProfileOverview;
