import FormInput from "../../../../../shared/components/form/FormInput";
import FormButton from "../../../../../shared/components/form/FormButton";

const ScorecardDetails = ({
  selectedScorecard,
  score,
  setScore,
  loading,
  onSubmit,
  handleCancel,
}) => {
  if (!selectedScorecard) return null;

  return (
    <div className="bg-white pb-4 rounded-md p-5">
      <h3 className="font-bold text-yankee_blue mb-0 text-lg capitalize">
        {selectedScorecard?.lastName || ""} {selectedScorecard?.firstName || ""}
      </h3>

      <div className="flex items-center mb-5 gap-1">
        <p className="text-xs capitalize">{selectedScorecard?.term} term</p>
        <p className="text-xs capitalize">
          {selectedScorecard?.session} session
        </p>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <FormInput
          label={"C.A 1"}
          type={"number"}
          disabled={loading}
          max={20}
          value={score?.continuousAssessmentOneScore}
          onChange={(e) =>
            setScore({
              ...score,
              continuousAssessmentOneScore: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"C.A 2"}
          type={"number"}
          max={20}
          disabled={loading}
          value={score?.continuousAssessmentTwoScore}
          onChange={(e) =>
            setScore({
              ...score,
              continuousAssessmentTwoScore: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"Exam"}
          disabled={loading}
          type={"number"}
          max={70}
          value={score?.examScore}
          onChange={(e) =>
            setScore({
              ...score,
              examScore: e.currentTarget.value,
            })
          }
        />

        <FormButton
          title={"Update Score"}
          type="submit"
          width={"100%"}
          loading={loading}
        />

        <button
          className="h-9 xs:h-10 ssm:h-11 bg-white w-full rounded-md text-coral_red text-xs font-bold mt-2"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};
export default ScorecardDetails;
