import { NavLink, useParams } from "react-router-dom";

import EmptyTable from "../../../shared/components/EmptyTable";
import IMAGES from "../../../shared/constant/images";
import Loader from "../../../shared/components/Loader";
import useFetchActiveSubjectRoles from "../../../shared/api/dashboard/useFetchActiveSubjectRoles";

const SubjectRoleTable = () => {
  const { teacherId } = useParams();

  const [roles, loading] = useFetchActiveSubjectRoles();

  return (
    <div className="bg-white shadow-sm py-5 mt-10">
      <div className="pb-5 px-3 border-b">
        <h2 className="text-yankee_blue font-semibold text-base">
          Subject Roles
        </h2>
      </div>

      <div className="table_wrapper">
        <div className="min-w-[700px]">
          {/* table header */}
          <div className="grid grid-cols-8 border-b">
            <div className="col-span-3 border-r p-3">
              <h5 className="font-semibold text-sm">School Name</h5>
            </div>

            <div className="border-r p-3 col-span-2">
              <h5 className="font-semibold text-sm">Subject</h5>
            </div>

            <div className="p-3 border-r">
              <h5 className="font-semibold text-sm">Class</h5>
            </div>

            <div className="p-3 border-r">
              <h5 className="font-semibold text-sm">Category</h5>
            </div>

            <div className="p-3">
              <h5 className="font-semibold text-sm">Action</h5>
            </div>
          </div>

          {loading ? <Loader loading={loading} /> : null}

          {!loading && !roles ? (
            <EmptyTable
              image={IMAGES.subjectBook}
              message={"You haven't been assigned to a class subject role yet"}
            />
          ) : null}

          {/* Table body */}
          {roles?.map((subjectRole, i) => (
            <div className="grid grid-cols-8 border-b" key={i}>
              <div className="col-span-3 border-r p-3">
                <p className="text-independence text-sm capitalize">
                  {subjectRole?.school?.name}
                </p>
              </div>

              <NavLink
                to={`/${teacherId}/workspace/${subjectRole?.school?.schoolId}/subject/${subjectRole?.classSubjectId}/class/${subjectRole?.classDetails?.classId}`}
                className={" col-span-2"}
              >
                <div className="border-r p-3">
                  <p className="text-primary text-sm cursor-pointer underline capitalize">
                    {subjectRole?.name}
                  </p>
                </div>
              </NavLink>

              <div className="p-3 border-r">
                <p className="text-independence text-sm capitalize">
                  {`${subjectRole?.classDetails?.category} ${subjectRole?.classDetails?.suffix}`}
                </p>
              </div>

              <div className="p-3 border-r">
                <p className="text-independence text-sm capitalize">
                  {subjectRole?.category
                    ? `${subjectRole?.category} class`
                    : "-"}
                </p>
              </div>

              <div className="p-3">
                <p className="text-independence text-sm">-</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SubjectRoleTable;
