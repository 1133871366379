import { FiUsers } from "react-icons/fi";
import { useSelector } from "react-redux";

import { getTotalStudents } from "../../../../../shared/utils/functions";
import { selectUserDetails } from "../../../../../redux/slices/auth/selectors";

import Loader from "../../../../../shared/components/Loader";
import StudentOverviewCard from "../../components/StudentOverviewCard";

const StudentsOverview = ({ classDetails, classLoading }) => {
  const details = useSelector(selectUserDetails);

  return (
    <>
      {classLoading ? (
        <Loader loading={classLoading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header">
          <div className="px-3">
            <h4 className="text-yankee_blue font-bold text-base capitalize">
              {details?.firstName} {details?.lastName}
            </h4>
            <p className="text-sm text-greyX11">Class Teacher</p>
          </div>

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={getTotalStudents(classDetails) || 0}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Male"}
            value={classDetails?.maleStudents || 0}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />
          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Female"}
            value={classDetails?.femaleStudents || 0}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};
export default StudentsOverview;
