import FormButton from "../../../../../shared/components/form/FormButton";

const Subjects = ({
  subjects,
  selectedSubject,
  handleSelectSubject,
  createScorecard,
  addingScorecard,
}) => {
  return (
    <div className="bg-white py-4 rounded-md">
      <div className="mb-10">
        {subjects?.map((subject, i) => {
          //   const [found] = selectedSubjects?.filter(
          //     (el) => el?.classSubjectId === subject?.classSubjectId
          //   );

          return (
            <div
              key={i}
              className={`flex items-center px-3 mb-3 h-12 rounded-sm cursor-pointer ${
                selectedSubject?.classSubjectId === subject?.classSubjectId
                  ? "border-primary border"
                  : "bg-cultured"
              }`}
              onClick={() => handleSelectSubject(subject)}
            >
              <p
                className={`text-sm font-semibold capitalize ${
                  selectedSubject?.classSubjectId === subject?.classSubjectId
                    ? "text-primary"
                    : "text-yankee_blue"
                }`}
              >
                {subject?.name}
              </p>
            </div>
          );
        })}
      </div>

      <FormButton
        title={"Add Scorecard"}
        onClick={createScorecard}
        loading={addingScorecard}
      />
    </div>
  );
};

export default Subjects;
