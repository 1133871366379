import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchActiveSubjectRoles() {
  const { teacherId } = useParams();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const request = useCallback(async () => {
    try {
      setLoading(true);

      const res = await client.get(
        `/job/teacher/${teacherId}/subject-role/active`
      );

      setRoles(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [teacherId]);

  useEffect(() => {
    request();
  }, [request]);

  return [roles, loading, request];
}
