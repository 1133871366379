import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import ReportCardOverview from "./components/ReportCardOverview";
import useFetchClassSubjects from "../../../../shared/api/hooks/useFetchClassSubjects";
import ReportCardTable from "./components/ReportCardTable";
import ClassNavbar from "../components/ClassNavbar";
import useFetchClassReportCard from "../../../../shared/api/cards/useFetchClassReportCard";

const ReportCards = () => {
  const navigate = useNavigate();
  const { classId, teacherId, schoolId } = useParams();

  const currentCalendar = useSelector(selectCalendar);
  const [selectedTerm, setSelectedTerm] = useState("");

  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [subjectLoading, subjects] = useFetchClassSubjects(classId);

  const [reportCards, reportCardsLoading] = useFetchClassReportCard(
    currentCalendar?.session,
    selectedTerm
  );

  useEffect(() => {
    setSelectedTerm(currentCalendar?.term);
  }, [currentCalendar]);

  function handleSelect(params) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${params?.studentId}/reportcard`
    );
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">
          Report Cards{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <ClassNavbar activeIndex={5} />

        <div className="main_content">
          <ReportCardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            subjectLength={subjects?.length}
            currentCalendar={currentCalendar}
            handleSelectCalendar={setSelectedTerm}
            selectedTerm={selectedTerm}
          />

          <ReportCardTable
            data={reportCards}
            loading={reportCardsLoading}
            subjectLoading={subjectLoading}
            handleSelect={handleSelect}
          />
        </div>
      </div>
    </div>
  );
};
export default ReportCards;
