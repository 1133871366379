import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { handleToast } from "../../../utils/functions";

import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";

export default function useStartResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        const { data } = await client.post(
          `/auth/password/reset/start`,
          payload
        );

        if (data?.success) {
          navigate("/forgot-password/sent");
        }

        handleToast(data?.message);
        setLoading(false);
        navigate("/login", { replace: true });
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [navigate]
  );

  return [request, loading];
}
