import { BiTimeFive } from "react-icons/bi";
import upcomingEventImage from "../../../assets/images/upcoming-event.png";

const UpcominEventOverview = () => {
  return (
    <div className="bg-white px-6 py-4 rounded-md">
      <div className="mb-2 flex items-center justify-between">
        <h5 className="text-independence text-sm">Upcoming Event</h5>

        <div className="flex items-center gap-2 py-1 rounded-md bg-azureish_white px-2">
          <BiTimeFive className="text-xs text-primary" />
          <p className="text-xxs text-primary font-bold">00pm - 00pm</p>
        </div>
      </div>

      <div className="flex items-center justify-center my-5">
        <div className="h-[128px] w-[200px]">
          <img
            src={upcomingEventImage}
            alt=""
            className="h-full w-full object-contain"
          />
        </div>
      </div>

      <p className="text-center text-yankee_blue text-sm">No Upcoming Events</p>
    </div>
  );
};
export default UpcominEventOverview;
