import React from "react";
import { FaUser } from "react-icons/fa";

export default function Avatar({ imageUrl, size, className }) {
  return (
    <div
      className={`h-8 w-8 rounded-full overflow-hidden flex items-center justify-center bg-icon-black-bg min-[400px]:h-12 min-[400px]:w-12  ${className}`}
      style={{ height: size, width: size }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt=""
          className="block object-cover w-full h-full"
        />
      ) : (
        <FaUser color="white" size={size ? size / 2 : 15} />
      )}
    </div>
  );
}
