import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import FormButton from "../../../../../shared/components/form/FormButton";
import FormInput from "../../../../../shared/components/form/FormInput";
import client from "../../../../../shared/api/client";
import handleApiError from "../../../../../shared/hooks/handleApiError";

const ModifyRemark = ({
  reportCard,
  fetchReportCard,
  closeSideModal,
  action,
}) => {
  const { teacherId } = useParams();
  const [classTeacherRemark, setClassTeacherRemark] = useState(
    reportCard?.classTeacherRemark
  );

  useEffect(() => {
    setClassTeacherRemark(reportCard?.classTeacherRemark);
  }, [reportCard]);

  const [submitting, setSubmitting] = useState(false);

  async function submitRemark() {
    try {
      setSubmitting(true);

      const { data } = await client.patch(
        `/result/reportcard/updateClassTeacherRemark/${reportCard?.reportCardId}`,
        { classTeacherRemark, teacherId }
      );

      fetchReportCard();
      setSubmitting(false);

      toast(data?.message);
      closeSideModal();
    } catch (error) {
      setSubmitting(false);
      handleApiError(error);
    }
  }

  if (action !== "teacher-remark") return null;

  return (
    <div>
      <div className="mb-3">
        <h2 className="side_page_title !mb-2 capitalize">
          Class Teacher Remark
        </h2>
        <p className="text-xs text-independence">
          Add class teacher remark to report card
        </p>
      </div>

      <div className="mt-10">
        <FormInput
          label={"Class Teacher Remark"}
          value={classTeacherRemark || ""}
          onChange={(e) => setClassTeacherRemark(e.currentTarget.value)}
        />

        <FormButton
          onClick={submitRemark}
          loading={submitting}
          className={"mt-5"}
          title="Update Remark"
        />

        <FormButton
          title={"Cancel"}
          className={"!bg-transparent !text-coral_red mt-3 !border-coral_red"}
          onClick={closeSideModal}
        />
      </div>
    </div>
  );
};

export default ModifyRemark;
