import { useCallback, useEffect, useState } from "react";
import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";
import { useParams } from "react-router-dom";

export default function useFetchInvitation() {
  const { inviteId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (inviteId) => {
    setLoading(true);

    try {
      const response = await client.get(`/auth/invite/${inviteId}`);

      setData(response.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    request(inviteId);
  }, [request, inviteId]);

  return [data, loading, request];
}
