import { useParams } from "react-router-dom";

import SubjectOverview from "./components/SubjectOverview";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import SubjectTable from "./components/SubjectTable";
import useFetchClassSubjects from "../../../../shared/api/hooks/useFetchClassSubjects";
import ClassNavbar from "../components/ClassNavbar";

const Subjects = () => {
  const { classId } = useParams();
  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [subjectLoading, subjects] = useFetchClassSubjects(classId);

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">
          Subjects{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <ClassNavbar activeIndex={2} />

        <div className="main_content">
          <SubjectOverview
            classDetails={classDetails}
            classLoading={classLoading}
          />

          <SubjectTable loading={subjectLoading} data={subjects} />
        </div>
      </div>

      {/* <div className="page_right_bar"></div> */}
    </div>
  );
};

export default Subjects;
