import { BsFillCircleFill } from "react-icons/bs";

const RolesV2 = ({ label, total, inactive, active }) => {
  return (
    <div className="bg-white px-3 py-3 rounded-sm">
      <h5 className="text-independence text-sm mb-2">{label}</h5>

      <div className="h-[50px] flex items-end justify-between">
        <p className="text-yankee_blue text-3xl font-bold">{total || 0}</p>

        <div className="">
          {active >= 0 ? (
            <div className="flex items-center gap-2 mb-1">
              <BsFillCircleFill className="text-xxxs text-primary" />
              <p className="text-xs text-auro_metal_saurus">
                Active Roles:{" "}
                <span className="font-bold text-yankee_blue">{active}</span>
              </p>
            </div>
          ) : null}

          {inactive >= 0 ? (
            <div className="flex items-center gap-2">
              <BsFillCircleFill className="text-xxxs text-secondary" />
              <p className="text-xs text-auro_metal_saurus">
                Inactive Roles:{" "}
                <span className="font-bold text-yankee_blue">{inactive}</span>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default RolesV2;
