import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectUserDetails } from "../../redux/slices/auth/selectors";

import BasicProfile from "./components/BasicProfile";
import ContactProfile from "./components/ContactProfile";
import MedicalProfile from "./components/MedicalProfile";
import EducationProfile from "./components/EducationProfile";
import useFetchUserDetails from "../../shared/api/user/useFetchUserDetails";

const Profile = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get("type");

  const navigate = useNavigate();
  const { teacherId } = useParams();

  const details = useSelector(selectUserDetails);
  const [fetchUser] = useFetchUserDetails();

  function handleCancel() {
    navigate(`/${teacherId}/profile`);
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-10">Profile</h1>

        <div className="table_wrapper">
          <div className="main_content_header">
            <NavLink to={`/${teacherId}/profile?type=basic`}>
              <button
                className={`main_content_header_btn ${
                  type === "basic" ? "main_content_header_btn_active" : ""
                }`}
              >
                Basic
              </button>
            </NavLink>

            <NavLink to={`/${teacherId}/profile?type=contact`}>
              <button
                className={`main_content_header_btn ${
                  type === "contact" ? "main_content_header_btn_active" : ""
                }`}
              >
                Contact
              </button>
            </NavLink>

            <NavLink to={`/${teacherId}/profile?type=medical`}>
              <button
                className={`main_content_header_btn ${
                  type === "medical" ? "main_content_header_btn_active" : ""
                }`}
              >
                Medical
              </button>
            </NavLink>

            <NavLink to={`/${teacherId}/profile?type=education`}>
              <button
                className={`main_content_header_btn ${
                  type === "education" ? "main_content_header_btn_active" : ""
                }`}
              >
                Education
              </button>
            </NavLink>
          </div>
        </div>

        <div className="main_content px-5">
          {type === "basic" ? (
            <BasicProfile
              user={details}
              fetchUser={fetchUser}
              handleCancel={handleCancel}
            />
          ) : null}

          {type === "contact" ? (
            <ContactProfile
              user={details}
              fetchUser={fetchUser}
              handleCancel={handleCancel}
            />
          ) : null}

          {type === "medical" ? (
            <MedicalProfile
              user={details}
              fetchUser={fetchUser}
              handleCancel={handleCancel}
            />
          ) : null}

          {type === "education" ? (
            <EducationProfile
              user={details}
              fetchUser={fetchUser}
              handleCancel={handleCancel}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Profile;
