import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineDesktopWindows } from "react-icons/md";
import { useSelector } from "react-redux";

import { IoNotificationsOutline } from "react-icons/io5";
import { BsPerson } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";

import Logo from "./Logo";
import { selectTotalUnreadNotifications } from "../../redux/slices/notification/selectors";

const Sidebar = ({ toggleMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { teacherId } = useParams();

  const [activeTab, setActiveTab] = useState("");
  const totalUnreadNotif = useSelector(selectTotalUnreadNotifications);

  useEffect(() => {
    setActiveTab(location.pathname?.split("/"));
  }, [location, setActiveTab]);

  async function logout() {
    try {
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {}
  }

  return (
    <ProSidebar
      className="h-full bg-white border-none"
      backgroundColor="white"
      width="230px"
    >
      <div className="px-5 py-5 mb-5">
        <Logo />
      </div>

      <Menu
      // menuItemStyles={{
      //   button: {
      //     // the active class will be added automatically by react router
      //     // so we can use it to style the active menu item
      //     [`&.active`]: {
      //       // backgroundColor: "#13395e",
      //       color: "#059F8B",
      //       // borderLeft: "4px solid #059F8B",
      //       fontWeight: "bold",
      //     },
      //   },
      // }}
      >
        <MenuItem
          icon={<RxDashboard />}
          component={<NavLink to={`/${teacherId}/dashboard`} />}
          className={`${
            activeTab?.[2] === "dashboard"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Dashboard
        </MenuItem>

        <MenuItem
          icon={<MdOutlineDesktopWindows />}
          component={<NavLink to={`/${teacherId}/workspace`} />}
          className={`${
            activeTab?.[2] === "workspace"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Workspace
        </MenuItem>

        <MenuItem
          icon={<BsPerson />}
          component={<NavLink to={`/${teacherId}/profile?type=basic`} />}
          className={`${
            activeTab?.[2] === "profile" ? "sidebar_active" : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Profile
        </MenuItem>

        <MenuItem
          icon={<IoNotificationsOutline />}
          component={<NavLink to={`/${teacherId}/notifications`} />}
          className={`${
            activeTab?.[2] === "notifications"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Notification
          <span className="ml-5 bg-coral_red text-white text-xxs p-[3px] rounded-md">
            {totalUnreadNotif}
          </span>
        </MenuItem>

        <MenuItem
          onClick={logout}
          icon={<BiLogOut size={16} />}
          className="sidebar_inactive !text-coral_red"
        >
          Logout
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};
export default Sidebar;
