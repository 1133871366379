import { useEffect, useRef, useState } from "react";
import { Country, State } from "country-state-city";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormButton from "../../../shared/components/form/FormButton";
import FormInput from "../../../shared/components/form/FormInput";
import SelectInput from "../../../shared/components/form/SelectInput";
import Avatar from "../../../shared/components/Avatar";
import handleApiError from "../../../shared/hooks/handleApiError";
import client from "../../../shared/api/client";

const BasicProfile = ({ user, fetchUser, handleCancel }) => {
  const { teacherId } = useParams();
  const filePickerRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [details, setDetails] = useState({
    address: "",
    religion: "",
    gender: "",
    ethnicity: "",
    maritalStatus: "",
    nationality: "",
    stateOfOrigin: "",
    lga: "",
    dob: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    setDetails({
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phone: user?.phone || "",
      address: user?.address || "",
      gender: user?.gender || "",
      religion: user?.religion || "",
      dob: user?.dob || "",
      nationality: user?.nationality || "",
      stateOfOrigin: user?.stateOfOrigin || "",
      lga: user?.lga || "",
      ethnicity: user?.ethnicity || "",
      maritalStatus: user?.maritalStatus || "",
    });
  }, [user]);

  const countries = Country.getAllCountries()?.map((country) => ({
    value: country.isoCode,
    ...country,
  }));

  const states = (countryCode) =>
    State.getStatesOfCountry(countryCode).map((state) => ({
      value: state.isoCode,
      ...state,
    }));

  function resetFilePickerRef() {
    filePickerRef.current.value = "";
    setSelectedFile(null);
    setSelectedImage(null);
  }

  function selectImage(e) {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }

    reader.onload = (readerEvent) => {
      setSelectedImage(readerEvent.target.result);
    };

    setSelectedFile(e.target.files[0]);
  }

  async function submit() {
    const country = Country.getCountryByCode(selectedCountry);
    const state = State.getStateByCodeAndCountry(
      selectedState,
      selectedCountry
    );

    if (selectedCountry && selectedState) {
      details.nationality = country.name;
      details.stateOfOrigin = state.name;
    } else if (selectedCountry && !selectedState) {
      if (country.name !== user?.nationality)
        return toast("Update state of origin");

      details.nationality = country.name;
    } else if (!selectedCountry && selectedState) {
      details.stateOfOrigin = state.name;
    }
    setLoading(true);

    try {
      const formData = new FormData();

      formData.append("image", selectedFile);
      formData.append("firstName", details?.firstName);
      formData.append("lastName", details?.lastName);
      formData.append("email", user?.email);
      formData.append("phone", details?.phone);
      formData.append("address", details?.address);
      formData.append("dob", details?.dob);
      formData.append("ethnicity", details?.ethnicity);
      formData.append("gender", details?.gender);
      formData.append("lga", details?.lga);
      formData.append("maritalStatus", details?.maritalStatus);
      formData.append("nationality", details?.nationality);
      formData.append("religion", details?.religion);
      formData.append("stateOfOrigin", details?.stateOfOrigin);

      const { data } = await client.patch(
        `/user/${teacherId}/basic`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      fetchUser();

      toast(data?.message);
      setLoading(false);
      setSelectedFile(null);
      setSelectedImage(null);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }

  return (
    <div className="">
      <h3 className="side_page_title">Edit basic profile</h3>

      <div className="flex items-center justify-center flex-col gap-2 mb-10">
        <Avatar
          imageUrl={selectedImage || user?.profileImage}
          className={"border"}
          size={80}
        />

        <input
          type={"file"}
          hidden
          name="image"
          ref={filePickerRef}
          onChange={selectImage}
          accept=".jpg, .jpeg, .png"
        />

        <div className="flex gap-2">
          <button
            className="flex items-center text-xs md:text-sm gap-1 border rounded-md px-2 py-1 cursor-pointer bg-primary text-white font-semibold"
            onClick={() => filePickerRef.current.click()}
          >
            <small>Change image</small>
          </button>

          {selectedFile ? (
            <button
              className="flex items-center text-xs md:text-sm gap-1 rounded-md px-2 py-1 cursor-pointer bg-white text-red-light border border-red-light font-semibold"
              onClick={() => resetFilePickerRef()}
            >
              <small>Cancel image</small>
            </button>
          ) : null}
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <FormInput
          label={"First Name"}
          placeholder={"Enter first name"}
          value={details?.firstName || ""}
          onChange={(e) =>
            setDetails({ ...details, firstName: e.target.value })
          }
        />

        <FormInput
          label={"Last Name"}
          placeholder={"Enter last name"}
          value={details?.lastName || ""}
          onChange={(e) => setDetails({ ...details, lastName: e.target.value })}
        />

        <FormInput
          label={"Email Address"}
          placeholder={"Enter email address"}
          disabled
          value={details?.email || ""}
          onChange={(e) => setDetails({ ...details, email: e.target.value })}
        />

        <FormInput
          label={"Phone Number"}
          placeholder={"Enter phone number"}
          maxLength={11}
          minLength={11}
          value={details?.phone || ""}
          onChange={(e) => setDetails({ ...details, phone: e.target.value })}
        />

        <FormInput
          label={"Address"}
          placeholder={"Enter your address"}
          value={details?.address || ""}
          onChange={(e) => setDetails({ ...details, address: e.target.value })}
        />

        <SelectInput
          label={"Religion"}
          placeholder={details?.religion || "Choose religion"}
          handleSelect={(e) =>
            setDetails({ ...details, religion: e.currentTarget.value })
          }
          options={[
            { name: "Islam", value: "Islam" },
            { name: "Christianity", value: "Christianity" },
            { name: "Other", value: "Other" },
          ]}
        />

        <SelectInput
          options={[
            { name: "Male", value: "male" },
            { name: "Female", value: "female" },
          ]}
          label={"Gender"}
          placeholder={details?.gender || "Choose gender"}
          handleSelect={(e) =>
            setDetails({ ...details, gender: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Ethnicity"}
          placeholder={"Enter your ethnicity"}
          value={details?.ethnicity || ""}
          onChange={(e) =>
            setDetails({ ...details, ethnicity: e.currentTarget.value })
          }
        />

        <SelectInput
          label={"Marital status"}
          placeholder={details?.maritalStatus || "Choose marital status"}
          handleSelect={(e) =>
            setDetails({ ...details, maritalStatus: e.currentTarget.value })
          }
          options={[
            { name: "Married", value: "Married" },
            { name: "Single", value: "Single" },
            { name: "Divorced", value: "Divorced" },
          ]}
        />

        <SelectInput
          label={"Nationality"}
          placeholder={details?.nationality || "Choose nationality"}
          handleSelect={(e) => setSelectedCountry(e.currentTarget.value)}
          options={countries}
        />

        <SelectInput
          label={"State of origin"}
          placeholder={details?.stateOfOrigin || "Choose state of origin"}
          handleSelect={(e) => setSelectedState(e.currentTarget.value)}
          options={states(selectedCountry)}
        />

        <FormInput
          label={"L.G.A"}
          placeholder={"Enter your LGA"}
          value={details?.lga || ""}
          onChange={(e) =>
            setDetails({ ...details, lga: e.currentTarget.value })
          }
        />
      </div>

      <div className="max-w-xs mx-auto my-10">
        <FormButton
          title={"Update"}
          className={"mt-5"}
          loading={loading}
          onClick={submit}
        />
      </div>
    </div>
  );
};
export default BasicProfile;
