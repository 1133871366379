import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormInput from "../../../shared/components/form/FormInput";
import client from "../../../shared/api/client";
import handleApiError from "../../../shared/hooks/handleApiError";
import FormButton from "../../../shared/components/form/FormButton";

const ContactProfile = ({ user, fetchUser, handleCancel }) => {
  const { teacherId } = useParams();

  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setDetails({
        twitter: user?.twitter || "",
        instagram: user?.instagram || "",
        facebook: user?.facebook || "",
        linkedIn: user?.linkedIn || "",
        emergencyContact2: user?.emergencyContact2 || "",
        emergencyContact1: user?.emergencyContact1 || "",
      });
    }
  }, [user]);

  async function submit() {
    setLoading(true);

    try {
      const { data } = await client.patch(
        `/user/${teacherId}/contact`,
        details
      );

      fetchUser();

      toast(data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }

  return (
    <div>
      <h3 className="side_page_title">Edit contact profile</h3>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <FormInput
          label={"Emergency contact 1"}
          placeholder={"Enter emergency contact 1"}
          value={details?.emergencyContact1 || ""}
          maxLength={11}
          onChange={(e) =>
            setDetails({ ...details, emergencyContact1: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Emergency contact 2"}
          placeholder={"Enter emergency contact 2"}
          value={details?.emergencyContact2 || ""}
          maxLength={11}
          onChange={(e) =>
            setDetails({ ...details, emergencyContact2: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Twitter link"}
          placeholder={"Enter your twitter link"}
          value={details?.twitter || ""}
          onChange={(e) =>
            setDetails({ ...details, twitter: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Facebook link"}
          placeholder={"Enter your facebook link"}
          value={details?.facebook || ""}
          onChange={(e) =>
            setDetails({ ...details, facebook: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Instagram link"}
          placeholder={"Enter your instagram link"}
          value={details?.instagram || ""}
          onChange={(e) =>
            setDetails({ ...details, instagram: e.currentTarget.value })
          }
        />

        <FormInput
          label={"LinkedIn link"}
          placeholder={"Enter your linkedIn link"}
          value={details?.linkedIn || ""}
          onChange={(e) =>
            setDetails({ ...details, linkedIn: e.currentTarget.value })
          }
        />
      </div>

      <div className="max-w-xs mx-auto my-10">
        <FormButton
          title={"Update"}
          className={"mt-5"}
          loading={loading}
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default ContactProfile;
