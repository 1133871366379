import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  getAverage,
  getScoreObtained,
} from "../../../../shared/utils/functions";
import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../../redux/slices/school/selectors";

import useFetchStudentBasicInfo from "../../../../shared/api/hooks/useFetchStudentBasicInfo";
import PageLoader from "../../../../shared/components/PageLoader";
import EmptyTable from "../../../../shared/components/EmptyTable";
import DeleteScorecard from "./components/DeleteScorecard";
import NewScorecard from "./components/NewScorecard";
import ModifyRemark from "./components/ModifyRemark";
import useFetchStudentReportCard from "../../../../shared/api/cards/useFetchStudentReportCard";
import ScorecardsByTermTableHeader from "./components/ScorecardsByTermTableHeader";
import ScorecardsByTermTableOverview from "./components/ScorecardsByTermTableOverview";
import ScorecardsByTermTableBody from "./components/ScorecardsByTermTableBody";
import Loader from "../../../../shared/components/Loader";
import useFetchCalendars from "../../../../shared/api/calendar/useFetchCalendars";

const StudentReportCard = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const scorecardId = query.get("scorecardId");
  const action = query.get("action");
  const school = useSelector(selectSchoolDetails);

  const navigate = useNavigate();
  const currentCalendar = useSelector(selectCalendar);
  const { classId, schoolId, studentId, teacherId } = useParams();

  const [studentDetailsLoading, studentDetails] =
    useFetchStudentBasicInfo(studentId);

  const [calendars, loadingCalendars] = useFetchCalendars(school?.state);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [reportCard, scorecards, reportCardLoading, fetchReportCard] =
    useFetchStudentReportCard(selectedCalendar);

  // calculate each term data
  const obtainableScore = scorecards?.length * 100;
  const scoreObtained = getScoreObtained(scorecards);

  useEffect(() => {
    setSelectedCalendar(currentCalendar);
  }, [currentCalendar]);

  function closeSideModal() {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard`
    );
  }

  function handleDeleteScorecard(scorecard) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?scorecardId=${scorecard?.scorecardId}&action=delete`
    );
  }

  function handleSelectCalendar(params) {
    const [session, termString] = params?.split(" - ");
    const term = termString?.split(" ")?.[0];

    const found = calendars?.find(
      (el) => el?.session === session && el?.term === term
    );

    setSelectedCalendar(found);
  }

  if (studentDetailsLoading)
    return <PageLoader loading={studentDetailsLoading} />;

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5 capitalize">
          {`${studentDetails?.lastName || ""} ${
            studentDetails?.firstName || ""
          }'s`}{" "}
          Report Card
        </h1>

        <div className="table_wrapper">
          <div className="main_content_header">
            <NavLink
              to={`/${teacherId}/workspace/${schoolId}/class/${classId}/reportcard`}
            >
              <button className="main_content_header_btn">Back to cards</button>
            </NavLink>

            {!reportCardLoading && !reportCard ? null : (
              <>
                <NavLink
                  to={`/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?action=new-scorecard`}
                >
                  <button
                    className={`main_content_header_btn ${
                      action === "new-scorecard"
                        ? "main_content_header_btn_active"
                        : ""
                    }`}
                  >
                    Add Scorecard
                  </button>
                </NavLink>

                <NavLink
                  to={`/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?action=teacher-remark`}
                >
                  <button
                    className={`main_content_header_btn ${
                      action === "teacher-remark"
                        ? "main_content_header_btn_active"
                        : ""
                    }`}
                  >
                    Class Teacher Remark
                  </button>
                </NavLink>
              </>
            )}
          </div>
        </div>

        <div className="main_content mb-10">
          <ScorecardsByTermTableOverview
            obtainableScore={obtainableScore}
            obtainedScore={scoreObtained}
            average={`${getAverage(scoreObtained, obtainableScore)}%`}
            calendars={calendars}
            loadingCalendars={loadingCalendars}
            reportCard={reportCard}
            selectedCalendar={selectedCalendar}
            handleSelectCalendar={handleSelectCalendar}
          />

          <div className="table_wrapper">
            <div className="min-w-[800px]">
              <ScorecardsByTermTableHeader />

              {reportCardLoading ? (
                <Loader loading={reportCardLoading} />
              ) : null}

              {!reportCardLoading && !scorecards?.length ? (
                <EmptyTable
                  // image={subjectBookImage}
                  message={"No scorecard records found"}
                />
              ) : null}

              <ScorecardsByTermTableBody
                data={scorecards}
                handleDeleteScorecard={handleDeleteScorecard}
                scorecardId={scorecardId}
              />
            </div>
          </div>
        </div>
      </div>

      {action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${action ? "block" : ""}`}>
        <DeleteScorecard
          action={action}
          scorecardId={scorecardId}
          handleCancelDeleteScorecard={closeSideModal}
          fetchReportCard={fetchReportCard}
        />

        <NewScorecard
          action={action}
          fetchReportCard={fetchReportCard}
          reportCard={reportCard}
          handleCancelNewScorecard={closeSideModal}
        />

        <ModifyRemark
          action={action}
          reportCard={reportCard}
          fetchReportCard={fetchReportCard}
          closeSideModal={closeSideModal}
        />
      </div>
    </div>
  );
};

export default StudentReportCard;
