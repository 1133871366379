import EmptyTable from "../../../../../shared/components/EmptyTable";
import Loader from "../../../../../shared/components/Loader";
import subjectBookImage from "../../../../../assets/images/subjectbook.png";

import {
  getGrade,
  getTotalForScorecard,
} from "../../../../../shared/utils/functions";

const ScorecardTable = ({ loading, data }) => {
  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-7 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">C.A 1</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">C.A 2</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Exam</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Total</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Grade</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable image={subjectBookImage} message={"No records found"} />
        ) : null}

        {/* Table body */}
        {data?.map((card, i) => (
          <div className="grid grid-cols-7 border-b" key={i}>
            <div className="col-span-2 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {card?.lastName} {card?.firstName}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm">
                {card?.continuousAssessmentOneScore}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {card?.continuousAssessmentTwoScore}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {card?.examScore}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {getTotalForScorecard(card)}
              </p>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {getGrade(getTotalForScorecard(card))}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ScorecardTable;
