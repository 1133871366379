import { useState } from "react";

import Pagination from "../../shared/components/Pagination";
import NotificationRow from "./components/NotificationRow";
import Loader from "../../shared/components/Loader";
import useFetchNotifications from "../../shared/api/notifications/useFetchNotifications";

const Notifications = () => {
  const [page, setPage] = useState(1);
  const [notifications, pagination, loading] = useFetchNotifications(page);

  return (
    <div>
      <div className="page">
        <div className="flex items-center mb-10">
          <h1 className="page_title flex-1">Notifications</h1>
        </div>

        <div className="main_content">
          {loading ? <Loader loading={loading} /> : null}

          {!loading &&
            notifications?.map((notification, i) => (
              <NotificationRow key={i} notification={notification} />
            ))}

          <Pagination setPage={setPage} pagination={pagination} />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
