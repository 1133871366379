import { AiFillCheckCircle } from "react-icons/ai";

import Logo from "../../../shared/components/Logo";
import authImage from "../../../assets/images/signup.png";

const ResetEmailSent = () => {
  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          <h2 className="auth_page_title">Reset Instruction Sent</h2>
          <p className="mb-10 text-yankee_blue text-base">
            Please follow the instruction in your mail to reset your password
          </p>

          <div className="grid place-items-center">
            <AiFillCheckCircle size={100} className="text-primary" />
          </div>
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img
            src={authImage}
            alt="GMF"
            className="block w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};
export default ResetEmailSent;
