import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { setUserDetails } from "../../../../redux/slices/auth/slice";
import { handleToast } from "../../../utils/functions";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useRegisterTeacherBySchoolInvitation() {
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);

        const { data } = await client.post(
          `/auth/registration/invite/${inviteId}`,
          payload
        );

        localStorage.setItem("token", data?.data?.token);
        dispatch(setUserDetails(data?.data?.user));

        handleToast(data?.message);
        setLoading(false);
        navigate(`/${data?.data?.user?.userId}/dashboard`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch, inviteId, navigate]
  );

  return [request, loading];
}
