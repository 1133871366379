import { FiUsers } from "react-icons/fi";

import Loader from "../../../../../shared/components/Loader";
import StudentOverviewCard from "../../../class/components/StudentOverviewCard";

import { getTotalStudents } from "../../../../../shared/utils/functions";

const ClassSubjectOverview = ({
  loading,
  scorecardLength,
  classDetails,
  subject,
}) => {
  return (
    <>
      {loading ? (
        <Loader loading={loading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[800px]">
          <div className="px-3">
            <h4 className="text-yankee_blue font-bold text-base capitalize">
              {subject}
            </h4>

            <p className="text-sm text-greyX11">
              {classDetails?.category} {classDetails?.suffix}
            </p>
          </div>

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={getTotalStudents(classDetails) || 0}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Scorecards"}
            value={scorecardLength || "-"}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Completed Cards"}
            value={"-"}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};
export default ClassSubjectOverview;
