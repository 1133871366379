const FormInput = ({
  label,
  type = "text",
  className = "mb-2",
  placeholder,
  onChange,
  leftIcon,
  rightIcon,
  onClickRightIcon,
  ...rest
}) => {
  return (
    <div className={className}>
      {label ? (
        <label className="text-yankee_blue font-semibold text-sm">
          {label}
        </label>
      ) : null}

      <div
        className={`rounded-sm overflow-hidden border border-greyX11 flex items-center px-1 h-9 xs:h-10 ssm:h-11 ${
          label && "mt-1"
        }`}
      >
        {leftIcon ? (
          <div className="h-full flex justify-center items-center px-1">
            {leftIcon}
          </div>
        ) : null}

        <input
          className="text-sm py-0 px-1 border-0 outline-0 bg-transparent flex-1 h-full text-light_slate_grey"
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />

        {rightIcon ? (
          <div
            className="h-full flex justify-center items-center px-1"
            onClick={rightIcon ? onClickRightIcon : null}
          >
            {rightIcon}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FormInput;
