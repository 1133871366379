import EmptyTable from "../../../../../shared/components/EmptyTable";
import Loader from "../../../../../shared/components/Loader";
import subjectBookImage from "../../../../../assets/images/subjectbook.png";

const SubjectTable = ({ loading, data }) => {
  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-6 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Subject Name</h5>
          </div>

          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">Teacher</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Group</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Date Created</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data ? (
          <EmptyTable
            image={subjectBookImage}
            message={"No subject records found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className="grid grid-cols-6 border-b"
            key={i}
            // onClick={() => handleNavigate(item)}
          >
            <div className="col-span-2 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.name}
              </p>
            </div>

            <div className="border-r p-3 col-span-2">
              <p className="text-independence text-sm capitalize">
                {item?.teacherId
                  ? `${item?.teacherTitle || ""} ${
                      item?.teacherLastName || ""
                    } ${item?.teacherFirstName || ""}`
                  : "N/A"}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.group}
              </p>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {new Date(item?.createdAt)?.toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubjectTable;
