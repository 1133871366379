import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

import Logo from "../../../shared/components/Logo";
import authImage from "../../../assets/images/signup.png";
import FormInput from "../../../shared/components/form/FormInput";
import FormButton from "../../../shared/components/form/FormButton";
import useCompleteResetPassword from "../../../shared/api/auth/recovery/useCompleteResetPassword";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [changePasswordRequest, loading] = useCompleteResetPassword();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");

  async function changePassword(e) {
    e.preventDefault();
    if (passwordOne !== passwordTwo) return toast("Passwords do not match");

    await changePasswordRequest({ token, password: passwordOne });
  }

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          <h2 className={"auth_page_title"}>Reset your password</h2>

          <form onSubmit={changePassword}>
            <FormInput
              label={"Password"}
              type={passwordVisible ? "text" : "password"}
              placeholder={"Enter new password"}
              required
              rightIcon={<AiOutlineEye className="w-3 h-3 cursor-pointer" />}
              onClickRightIcon={() => setPasswordVisible(!passwordVisible)}
              onChange={(e) => setPasswordOne(e.currentTarget.value)}
            />

            <FormInput
              label={"Confirm Password"}
              type={passwordVisible ? "text" : "password"}
              placeholder={"Confirm new password"}
              required
              rightIcon={<AiOutlineEye className="w-3 h-3 cursor-pointer" />}
              onClickRightIcon={() => setPasswordVisible(!passwordVisible)}
              onChange={(e) => setPasswordTwo(e.currentTarget.value)}
            />

            <FormButton
              title={"Change password"}
              type="submit"
              loading={loading}
              className="mt-[20%]"
            />

            <p
              className="text-yankee_blue text-xs mt-2 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Already have an account?{" "}
              <span className="text-primary">Sign In</span>
            </p>
          </form>
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img
            src={authImage}
            alt="GMF"
            className="block w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
