import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUserDetails } from "../../../../redux/slices/auth/slice";
import { handleToast } from "../../../utils/functions";

import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";

export default function useRegisterTeacher() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);

        const response = await client.post("/auth/register", payload);

        localStorage.setItem("token", response.data?.data?.token);
        dispatch(setUserDetails(response.data?.data?.user));
        handleToast(response.data?.message);

        setLoading(false);
        navigate(`/${response.data?.data?.user?.userId}/dashboard`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch, navigate]
  );

  return [request, loading];
}
