import { FiEdit3 } from "react-icons/fi";

import EmptyTable from "../../../../../shared/components/EmptyTable";
import Loader from "../../../../../shared/components/Loader";

import { getTotalForScorecard } from "../../../../../shared/utils/functions";

const ScorecardTable = ({
  loading,
  data,
  handleSelectScorecard,
  handleNavigate,
}) => {
  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-3 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">C.A 1</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">C.A 2</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Exam</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Total</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Action</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data ? (
          <EmptyTable
            //   image={subjectBookImage}
            message={"No card record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((card, i) => (
          <div className="grid grid-cols-8 border-b" key={i}>
            <div
              className="col-span-3 border-r p-3"
              onClick={() => handleNavigate(card)}
            >
              <p className="text-independence text-sm capitalize underline">
                {card?.lastName} {card?.firstName}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm">
                {card?.continuousAssessmentOneScore}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {card?.continuousAssessmentTwoScore}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {card?.examScore}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {getTotalForScorecard(card)}
              </p>
            </div>

            <div
              className="p-3 cursor-pointer"
              onClick={() => handleSelectScorecard(card)}
            >
              <FiEdit3 className="text-independence" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ScorecardTable;
