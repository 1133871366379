import Loader from "../../../../../shared/components/Loader";

const Subjects = ({
  subjects,
  handleSelectSubject,
  selectedSubject,
  subjectLoading,
  action,
}) => {
  if (action !== "show-subjects") return null;

  return (
    <div className="bg-white pb-4 rounded-md">
      <h5 className="text-independence text-base mb-5">Class Subjects</h5>

      {subjectLoading ? <Loader /> : null}

      <div>
        {!subjectLoading &&
          subjects?.map((subject, i) => (
            <div
              key={i}
              className={`flex items-center px-3 mb-3 h-12 rounded-md cursor-pointer ${
                selectedSubject?.classSubjectId === subject?.classSubjectId
                  ? "bg-primary"
                  : "bg-lotion "
              }`}
              onClick={() => handleSelectSubject(subject)}
            >
              <p
                className={`text-sm font-semibold capitalize ${
                  selectedSubject?.classSubjectId === subject?.classSubjectId
                    ? "text-white"
                    : "text-yankee_blue"
                }`}
              >
                {subject?.name}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Subjects;
