import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import ScorecardOverview from "./components/ScorecardOverview";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import ScorecardTable from "./components/ScorecardTable";
import useFetchClassSubjects from "../../../../shared/api/hooks/useFetchClassSubjects";
import Subjects from "./components/Subjects";
import ClassNavbar from "../components/ClassNavbar";
import useFetchScorecardsByClassSubjectId from "../../../../shared/api/cards/useFetchScorecardsByClassSubjectId";

const Scorecards = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");
  const navigate = useNavigate();

  const { classId, teacherId, schoolId } = useParams();
  const calendar = useSelector(selectCalendar);

  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [subjectLoading, subjects] = useFetchClassSubjects(classId);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const [scorecards, loading] = useFetchScorecardsByClassSubjectId(
    selectedSubject?.classSubjectId,
    calendar
  );

  function handleSelectSubject(params) {
    setSelectedSubject(params);
    closeSideModal();
  }

  function handleShowSubjectModal() {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/scorecards?action=show-subjects`
    );
  }

  function closeSideModal() {
    navigate(`/${teacherId}/workspace/${schoolId}/class/${classId}/scorecards`);
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">
          Scorecards{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <ClassNavbar activeIndex={4} />

        <div className="main_content">
          <ScorecardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            selectedSubject={selectedSubject}
            scorecardLength={scorecards?.length}
            subjectLength={subjects?.length}
            handleShowSubjectModal={handleShowSubjectModal}
          />

          <ScorecardTable data={scorecards} loading={loading} />
        </div>
      </div>

      {action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${action ? "block" : ""}`}>
        <Subjects
          subjects={subjects}
          handleSelectSubject={handleSelectSubject}
          selectedSubject={selectedSubject}
          subjectLoading={subjectLoading}
          action={action}
        />
      </div>
    </div>
  );
};
export default Scorecards;
