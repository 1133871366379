import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../redux/slices/school/selectors";
import { setCalendar } from "../../redux/slices/school/slice";
import { setUserDetails } from "../../redux/slices/auth/slice";
import { selectUserDetails } from "../../redux/slices/auth/selectors";

import Sidebar from "./Sidebar";
import client from "../api/client";
import handleApiError from "../hooks/handleApiError";
import Logo from "./Logo";
import { setTotalUnreadNotifications } from "../../redux/slices/notification/slice";
import useFetchSchool from "../api/school/useFetchSchool";

const Container = () => {
  const { teacherId } = useParams();
  const dispatch = useDispatch();
  useFetchSchool();

  const school = useSelector(selectSchoolDetails);
  const calendar = useSelector(selectCalendar);
  const details = useSelector(selectUserDetails);

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const fetchUser = useCallback(async () => {
    dispatch(setUserDetails(null));

    try {
      const { data } = await client.get(`/user/${teacherId}`);
      if (data?.success) dispatch(setUserDetails(data?.data));
    } catch (error) {
      handleApiError(error);
    }
  }, [dispatch, teacherId]);

  useEffect(() => {
    if (!details) fetchUser();
  }, [fetchUser, details]);

  const fetchCurrentCalendar = useCallback(async () => {
    try {
      const { data } = await client.get(
        `/calendar/state/${school?.state}/active`
      );
      dispatch(setCalendar(data?.data));
    } catch (error) {}
  }, [dispatch, school?.state]);

  useEffect(() => {
    if (!calendar && school) fetchCurrentCalendar();
  }, [calendar, fetchCurrentCalendar, school]);

  const getNotifications = useCallback(async () => {
    try {
      const res = await client.get(`/notification?recipient=${teacherId}`);

      const unreadNotifications = res.data?.data?.filter(
        (el) => !el?.isRead
      )?.length;
      dispatch(setTotalUnreadNotifications(unreadNotifications));
    } catch (error) {
      handleApiError(error);
    }
  }, [teacherId, dispatch]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <div className="bg-ghost_white min-h-screen">
      <div className="w-fit border-0 fixed top-0 bottom-0 left-0 hidden lg:block bg-white">
        <Sidebar />
      </div>

      <div className="flex items-center justify-between px-5 bg-white lg:hidden">
        <div className="py-5">
          <Logo />
        </div>

        <div className="cursor-pointer">
          <AiOutlineMenu color="#000000" size={30} onClick={toggleMenu} />
        </div>
      </div>

      <div className={`relative lg:ml-[230px] min-h-screen max-w-[1600px]`}>
        <Outlet />
      </div>

      <div
        className={`z-10 fixed top-0 left-0 bottom-0 right-0 lg:hidden ${
          open ? "visible" : "hidden"
        }`}
      >
        <div className="bg-white h-full w-fit">
          <Sidebar toggleMenu={toggleMenu} />
        </div>

        <div
          className="bg-black/50 w-full h-full fixed top-0 left-[230px] bottom-0 right-0"
          onClick={toggleMenu}
        />
      </div>

      {/* <div
        className={`relative ${
          windowWidth < 1300
            ? "ml-0 min-[800px]:ml-[100px]"
            : "min-[800px]:ml-[270px]"
        } min-h-screen`}
      >
        <Outlet />
      </div> */}
    </div>
  );
};
export default Container;
