import { NavLink, useParams } from "react-router-dom";

const ClassNavbar = ({ activeIndex }) => {
  const { classId, teacherId, schoolId } = useParams();

  return (
    <div className="main_content_header">
      <NavLink to={`/${teacherId}/workspace`}>
        <button
          className={`main_content_header_btn ${
            activeIndex === 0 ? "main_content_header_btn_active" : ""
          }`}
        >
          Workspace
        </button>
      </NavLink>

      <NavLink
        to={`/${teacherId}/workspace/${schoolId}/class/${classId}/students`}
      >
        <button
          className={`main_content_header_btn ${
            activeIndex === 1 ? "main_content_header_btn_active" : ""
          }`}
        >
          Students
        </button>
      </NavLink>

      <NavLink
        to={`/${teacherId}/workspace/${schoolId}/class/${classId}/subjects`}
      >
        <button
          className={`main_content_header_btn ${
            activeIndex === 2 ? "main_content_header_btn_active" : ""
          }`}
        >
          Subjects
        </button>
      </NavLink>

      <NavLink
        to={`/${teacherId}/workspace/${schoolId}/class/${classId}/attendance`}
      >
        <button
          className={`main_content_header_btn ${
            activeIndex === 3 ? "main_content_header_btn_active" : ""
          }`}
        >
          Attendance
        </button>
      </NavLink>

      <NavLink
        to={`/${teacherId}/workspace/${schoolId}/class/${classId}/scorecards`}
      >
        <button
          className={`main_content_header_btn ${
            activeIndex === 4 ? "main_content_header_btn_active" : ""
          }`}
        >
          Scorecards
        </button>
      </NavLink>

      <NavLink
        to={`/${teacherId}/workspace/${schoolId}/class/${classId}/reportcard`}
      >
        <button
          className={`main_content_header_btn ${
            activeIndex === 5 ? "main_content_header_btn_active" : ""
          }`}
        >
          Report Card
        </button>
      </NavLink>
    </div>
  );
};
export default ClassNavbar;
