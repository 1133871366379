import React from "react";
import { ScaleLoader } from "react-spinners";

export default function PageLoader({ loading }) {
  return (
    <div className="page-loader border h-screen flex items-center justify-center">
      <div className="bg-white p-5 rounded-xl">
        <ScaleLoader loading={loading} size={30} color="#3C94FC" />
      </div>
    </div>
  );
}
